html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

header{
    position: relative;
    z-index: 101;
}

.container.container {
    max-width: calc(1520rem/16);
    width: 100%;
    padding-left: calc(40rem / 16);
    padding-right: calc(40rem / 16);

    @media screen and (min-width: 768px){
        max-width: calc(1555rem/16);
        padding-left: calc(75rem / 16);
    }

    @media screen and (min-width: 1400px){
        max-width: calc(1580rem/16);
        padding-left: calc(100rem / 16);
    }

    @media screen and (min-width: 1730px){
        max-width: calc(1520rem/16);
        padding-left: calc(40rem / 16);
    }
}

.container-narrow {
    max-width: calc(944rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-sm {
    max-width: calc(737rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-md {
    max-width: calc(1194rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.page-wrapper {
    overflow: hidden;
    position: relative;
}

@media screen and (max-width: 767px){
    .container.container--no-padding-xs {
        padding: 0;
        overflow: hidden;
    }
}

/* fix container in container  */
.container > .container {
    padding: 0;
}

.bg-cover {
    background-size: cover;
}
