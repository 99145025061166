.hero {
    position: relative;
}
.hero-dark-overlay {
    z-index: 1;
}
.hero__placeholder-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.pimcore_tag_video {
    height: 100%;
}
.pimcore_tag_video *{
    object-fit: cover;
}
#pimcore_video_hero-video iframe {
    pointer-events: none;
}
.hero__background {
    height: calc(100vh - calc(115rem / 16));
    max-height: calc(1000rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(100vh - calc(70rem / 16));
        min-height: 100%;
    }
    @media screen and (min-width: 992px) {
        max-height: none;
    }
}
.hero__background__img {
    background-size: cover;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media screen and (min-width: 768px) {
        top: -3%;
        left: -3%;
        right: -3%;
        bottom: -3%;
    }

    @media (hover: none) and (pointer: coarse) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.hero__background__img--top {
    position: absolute;
    height: calc(840rem / 16);
    left: -3%;
    right: -3%;
    bottom: -3%;
    opacity: 0;
    animation: fadeInHero 1 forwards 800ms ease-in-out;
    animation-delay: 650ms;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media screen and(hover: none) and (pointer: coarse) {
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.hero__background__img__img {
    max-height: calc(840rem / 16);
}
@keyframes fadeInHero {
    from { opacity: 0; }
    to {opacity: 1;}
}
.hero__content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    font-size: calc(20rem / 16);
    line-height: calc(33/20);
    z-index: 1;
}

/* title */
.hero__content__title {
    font-size: calc(24rem / 16);
    font-weight: 900;
    font-family: "Arapey";

    @media screen and (min-width: 768px) {
        font-size: calc(50rem / 16);
    }
}
.hero__content__subtitle {
    font-size: calc(22rem / 16);
    line-height: calc(43/30);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
.hero__content__title-lg {
    font-size: calc(28rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(64rem / 16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(100rem / 16);
    }
}

.hero__content__text {
    position: relative;
    padding-top: calc(30rem / 16);
    margin-top: calc(20rem / 16);
}
.hero__content__text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(207rem / 16);
    height: calc(6rem / 16);
    background: var(--color-primary);
}

.hero__content--md .hero__content__title {
    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
        letter-spacing: calc(15rem / 16);
    }

    @media screen and (min-width: 1200px) {
        letter-spacing: calc(30rem / 16);
    }
}

.hero__content--lg .hero__content__title {
    font-size: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(90rem / 16);
        line-height: calc(129/90);
    }
}
.hero__content--lg .hero__content__subtitle {
    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
        line-height: calc(44/40);
    }
}

/* hero with text shadow */
.hero__content--shadow {
    text-shadow: calc(1rem / 16) calc(1rem / 16) 0 #000;
}
.hero__content--shadow .btn {
    text-shadow: none;
}

.hero__content--lg.hero__content--shadow .hero__content__title{
    text-shadow: calc(2rem / 16) calc(3rem / 16) 0 #000000;
}

.hero-brand__img {
    margin: 0 3rem;
    width: calc(50rem / 14);
    height: calc(80rem / 14);

    @media screen and (min-width: 768px) {
        width: calc(79rem / 14);
        height: calc(126rem / 14);
    }
}

.hero__image {
    display: flex;
    flex-direction: column;
    position: relative;
}

.hero__image--small{
    margin-bottom: 11rem;

    @media screen and (max-width: 767px){
        margin-bottom: 0;
    }
}

.hero__image__content--bottom {
    padding: 9.1rem;

    @media screen and (max-width: 767px) {
        padding: 0;
    }
}

.hero__image:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(45deg,  rgba(0,0,0,.8) 0%,rgba(0,0,0,0) 100%);
}
.hero__image__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.hero__image__content-wrapper--overlay-xs {
    z-index: 1;

    @media screen and (min-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - calc(70rem / 16));
    }
    @media screen and (max-width: 767px) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.hero__image__content-wrapper--overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    @media screen and (max-width: 767px) {
        justify-content: center;
        align-items: center;
    }
}

.hero__image__content-wrapper{
    width: 100%;
    /*min-height: calc(100vh - calc(120rem / 16));*/
    height: calc(100vh - calc(120rem / 16));
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    @media screen and (min-width: 768px) {
        height: auto;
        min-height: calc(100vh - calc(70rem / 16));
    }
}
.hero__image__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: calc(80rem / 16) 0 calc(45rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) 0 calc(110rem / 16) calc(75rem / 16);
        font-size: calc(18rem / 16);
    }
    @media screen and (min-width: 1200px) {
        padding: calc(100rem / 16) calc(75rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(20rem / 16);
    }
}
.hero__bottom {
    z-index: 1;
}
.hero__bottom:not(.hero__bottom--sm) {
    @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(calc(105rem / 16));
        overflow: hidden;
        width: 65%;
        max-width: calc(640rem / 16);
    }
}
.hero__bottom--sm {
    @media screen and (min-width: 768px) {
        width: 100%;
    }
    @media screen and (min-width: 1200px) {
        width: 44%;
        max-width: calc(640rem / 16);
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(calc(105rem / 16));
        overflow: hidden;
    }
}

.hero__slider .slick-dots--default{
    position: absolute;
    bottom: calc(12rem / 16);
    left: 0;
    right: 0;
}

/* slider animation */
.hero__slider .hero__image__content {
    transition: transform 700ms ease-in-out, opacity 700ms ease-in-out;
    opacity: 0;
    transform: translateX(calc(-60rem / 16));
    transition-delay: 180ms;
}
.slick-current .hero__image__content {
    opacity: 1;
    transform: translateX(0);
}
/* Hero image/video area brick  */
.pimcore_area_hero-image-video {
    margin-bottom: 25px;
}

.pimcore_area_hero-image-video .hero__content__title {
    letter-spacing: 0px !important;
}

.front-page {
    height: 100vh;

    & .hero__content {
        bottom: 10%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    /* title */
    & .hero__content__title {
        font-weight: 900;
        font-family: "Arapey";

        @media screen and (min-width: 768px) {
            font-size: 4rem;
        }
    }
    & .hero__content__subtitle {
        font-style: italic;
        font-family: "Arapey";

        @media screen and (min-width: 768px) {
            font-size: 3rem;
        }
    }
    & .hero-arrow-container {
        position: absolute;
        z-index: 2;
        bottom: 2rem;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
    }
    & .hero-arrow__icon {
        font-size: calc(32rem / 16);
        font-weight: 900;
        color: rgba(255, 255, 255, 0.7);
    }
    & .hero-arrow__icon:hover {
        color: rgba(255, 255, 255, 1)
    }
    & .hero__link__container {
        margin: 0 auto;
        display: flex;
    }
    & .hero__link {
        width: 130px;
        color: rgba(255, 255, 255, 0.7);
    }
    & .hero__link:hover {
        color: rgba(255, 255, 255, 1);
    }
    & .hero__link__icon {
        font-size: 3rem;
    }
}

