.teaser-item {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.teaser-item .text-block.center {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
}

.teaser-item-headline {
    color: white;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 0.75rem;
}

.teaser-item-description {
    font-size: 1.13rem;
    margin-bottom: 1rem;
    color: white;
}

.teaser-item .text-block.bottom-left{
    text-align: left;
    position: absolute;
    bottom: 5%;
    left: 5%;
}

.teaser-item.animations > img {
    transition: transform .5s ease;
}

.teaser-item.animations > div > img {
    transition: transform .5s ease;
}

.teaser-item.animations:hover > div > img {
    transform: scale(1.05);
}

.teaser-item.animations:hover > img {
    transform: scale(1.05);
}

.teaser-item .arrow {
    width: 50px;
    font-weight: bold;
    border-width: 2px;
}

.teaser-item .arrow span {
    font-weight: bold;
    top: 1px;
}

.object-fit-cover {
    object-fit: cover;
}

.mh-100 {
    min-height: 100% !important;
}

.mh-200{
    min-height: 200px !important;
}

.h-200 {
    height: 200px !important;
}


.h-250 {
    height: 250px !important;
}

.h-300 {
    height: 300px !important;
}
@media (max-width: 576px) {
    .h-300{
        height: 200px !important;
    }
}


.h-350 {
    height: 350px !important;
}
@media (max-width: 576px) {
    .h-350{
        height: 200px !important;;
    }
}


.h-400 {
    height: 400px !important;
}
@media (max-width: 576px) {
    .h-400{
        height: 200px !important;;
    }
}

@media (max-width: 576px) {
    .h-400 {
        height: 200px !important;
    }
}

.h-450 {
    height: 450px !important;
}

@media (max-width: 576px) {
    .h-450 {
        height: 350px !important;
    }
}

.h-500 {
    height: 500px !important;
}
@media (max-width: 576px) {
    .h-500 {
        height: 300px !important;
    }
}

.border-dashed {
    border: 1px dashed black;
}

.content-teaser-column .pimcore_area_content-teaser-item {
    height: 100%;
    width: 100%;
}

.content-teaser-column .pimcore_tag_video.pimcore_editable_video {
    height: 100%;
    width: 100%;
}

.text-block.bottom-left.d-flex.align-items-center.justify-content-center > div:not(:last-child){
    margin-right: 20px;
}

.teaser-item .pimcore_tag_image_empty{
    min-height: 200px !important;
}

.teaser-item > .image-overlay {
    z-index: 2;
}


.teaser-item > .image-overlay:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 20;
}

.mb-last-0:last-child {
    margin-bottom: 0!important;
}

