.console {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-top: calc(1rem / 16) solid var(--color-grey);
    z-index: 2;

    @media screen and (min-width: 768px) {
        border-top: 0;
        width: calc(70rem / 16);
        right: auto;
        top: 0;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: calc(20rem / 16) 0;
        border-right: calc(1rem / 16) solid var(--color-grey);
    }
}
.console__item {
    padding: calc(10rem / 16);
    font-size: calc(13rem / 16);
    line-height: 1;

    @media screen and (max-width: 767px) {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: calc(15rem / 16) calc(5rem / 16);
    }
}
.console__item--text{
    font-size: calc(18rem / 16);
}
.console__item__icon {
    font-size: calc(24rem / 16);
    line-height: 1;

    @media screen and (min-width: 786px) {
        font-size: calc(30rem / 16);
    }
}
.console__item__icon.console__item__icon--sm {
    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.console__item__text-icon {
    font-size: calc(20rem / 16);
}
.console__item:not(:first-child) {
    border-left: 1px solid var(--color-grey);

    @media screen and (min-width: 768px) {
        border: 0;
        margin-top: calc(12rem / 16);
    }
}